// Copyright (C) 2023 CaliperAI Corporation
//
// SPDX-License-Identifier: MIT

import './styles.scss';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import notification from 'antd/lib/notification';
import Spin from 'antd/lib/spin';
import Text from 'antd/lib/typography/Text';
import { SettingOutlined } from '@ant-design/icons';
// eslint-disable-next-line import/no-extraneous-dependencies
import * as math from 'mathjs';

import CaliperGTTooltip from 'components/common/calipergt-tooltip';
import { CombinedState } from 'reducers';
import ContextImageSelector from './context-image-selector';

interface Props {
    offset: number[];
}

interface Calibration {
    transformation_matrix: number[][];
    camera_intrinsic: number[][];
}

interface SensorCalibrations {
    [key: string]: Calibration;
}

function applyMatrix4(matrix: any, corner: any): [any, any, any] {
    const [x, y, z] = corner;
    const transformedX = matrix[0] * x + matrix[4] * y + matrix[8] * z + matrix[12];
    const transformedY = matrix[1] * x + matrix[5] * y + matrix[9] * z + matrix[13];
    const transformedZ = matrix[2] * x + matrix[6] * y + matrix[10] * z + matrix[14];
    return [transformedX, transformedY, transformedZ];
}

function extractCubeCorners(data: any): any {
    const unitCubeCorners = [
        [-0.5, -0.5, -0.5],
        [0.5, -0.5, -0.5],
        [-0.5, 0.5, -0.5],
        [0.5, 0.5, -0.5],
        [-0.5, -0.5, 0.5],
        [0.5, -0.5, 0.5],
        [-0.5, 0.5, 0.5],
        [0.5, 0.5, 0.5],
    ];

    const matrix = data.perspective.matrix.elements;
    return unitCubeCorners.map((corner) => applyMatrix4(matrix, corner));
}

function arrayToMatrix(array_: number[] | number[][]): math.Matrix {
    return math.matrix(array_);
}

function projectPointToImage(point: math.Matrix, cameraIntrinsic: math.Matrix): [number, number] {
    const projectedPoint = math.multiply(cameraIntrinsic, point);
    const uValue = math.subset(projectedPoint, math.index(0));
    const vValue = math.subset(projectedPoint, math.index(1));
    const wValue = math.subset(projectedPoint, math.index(2));

    if (typeof uValue !== 'number' || typeof vValue !== 'number' || typeof wValue !== 'number') {
        throw new Error('Expected numerical values in projection calculation.');
    }

    const u = uValue / wValue;
    const v = vValue / wValue;
    return [Math.round(u), Math.round(v)];
}

function transformCorners(
    cornersMatrix: math.Matrix, transformationMatrix: math.Matrix,
): number[][] {
    let camPoints = math.multiply(transformationMatrix, cornersMatrix);
    camPoints = math.transpose(camPoints);

    return camPoints.toArray().map((row: number[]) => row.slice(0, 3));
}

function getPixelPositions(points: number[][], cameraIntrinsic: number[][]): [number, number][] {
    return points.map((point) => projectPointToImage(arrayToMatrix(point), arrayToMatrix(cameraIntrinsic)));
}

function getProjectedPointInFrame(points: [[number, number]], image: ImageBitmap): [number, number] | null {
    for (const point of points) {
        if (point[0] > 0 && point[0] < image.width && point[1] > 0 && point[1] < image.height) {
            return point;
        }
    }
    return null;
}

// Helper function to convert hex color to RGBA format
function hexToRgba(hex_: string, alpha: number): string {
    let r: number;
    let g: number;
    let b: number;
    let hex = hex_;

    if (hex.startsWith('#')) {
        hex = hex.slice(1);
    }

    if (hex.length === 3) {
        r = parseInt(hex[0] + hex[0], 16);
        g = parseInt(hex[1] + hex[1], 16);
        b = parseInt(hex[2] + hex[2], 16);
    } else if (hex.length === 6) {
        r = parseInt(hex.slice(0, 2), 16);
        g = parseInt(hex.slice(2, 4), 16);
        b = parseInt(hex.slice(4), 16);
    } else {
        throw new Error('Invalid HEX color format');
    }

    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

function drawCube(
    context: CanvasRenderingContext2D,
    projectedPoints: any,
    color: any,
    text: number,
    image: ImageBitmap,
    isActivated: boolean,
): void {
    const edges = [
        [0, 1],
        [1, 3],
        [3, 2],
        [2, 0], // bottom face
        [4, 5],
        [5, 7],
        [7, 6],
        [6, 4], // top face
        [0, 4],
        [1, 5],
        [2, 6],
        [3, 7], // connecting edges
    ];
    context.strokeStyle = color;
    context.lineWidth = 6;
    edges.forEach(([start, end]) => {
        context.beginPath();
        context.moveTo(projectedPoints[start][0], projectedPoints[start][1]);
        context.lineTo(projectedPoints[end][0], projectedPoints[end][1]);
        context.stroke();
        context.closePath();
    });

    if (isActivated) {
        // Fill the cuboid with a transparent color
        // Convert hex to RGBA with 20% opacity
        context.fillStyle = hexToRgba(color, 0.2);

        // Draw the faces of the cuboid (you may want to adjust which faces to fill)
        const faceIndices = [
            [0, 1, 3, 2], // Bottom face
            [4, 5, 7, 6], // Top face
            [0, 1, 5, 4], // Front face
            [2, 3, 7, 6], // Back face
            [0, 2, 6, 4], // Left face
            [1, 3, 7, 5], // Right face
        ];

        faceIndices.forEach((face: number[]) => {
            context.beginPath();
            face.forEach((index, i) => {
                const point = projectedPoints[index];
                if (i === 0) {
                    context.moveTo(point[0], point[1]);
                } else {
                    context.lineTo(point[0], point[1]);
                }
            });
            context.closePath();
            context.fill(); // Fill the face with transparent color
        });
    }

    const idPoint = getProjectedPointInFrame(projectedPoints, image);
    if (idPoint) {
        context.fillStyle = '#fff';
        const objectId = `Object ID: ${text}`;
        context.fillRect(idPoint[0], idPoint[1] - 23, 130, 26);
        context.font = '24px Arial';
        context.fillStyle = color;
        context.fillText(objectId, idPoint[0], idPoint[1]);
    }
}

function ContextImage(props: Props): JSX.Element {
    const { offset } = props;
    const defaultFrameOffset = offset[0] || 0;
    const defaultContextImageOffset = offset[1] || 0;

    const canvasRef = useRef<HTMLCanvasElement>(null);
    const job = useSelector((state: CombinedState) => state.annotation.job.instance);
    const { number: frame, relatedFiles } = useSelector((state: CombinedState) => state.annotation.player.frame);
    const frameIndex = frame + defaultFrameOffset;

    const [contextImageData, setContextImageData] = useState<Record<string, ImageBitmap>>({});
    const [sensorCalibrations, setSensorCalibrations] = useState<SensorCalibrations | null>(null);
    const [fetching, setFetching] = useState<boolean>(false);
    const [contextImageOffset, setContextImageOffset] = useState<number>(
        Math.min(defaultContextImageOffset, relatedFiles),
    );

    const [zoomLevel, setZoomLevel] = useState<number>(1);
    const [isDragging, setIsDragging] = useState<boolean>(false);
    const [dragStart, setDragStart] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
    const [dragOffset, setDragOffset] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
    const [hasError, setHasError] = useState<boolean>(false);
    const [showSelector, setShowSelector] = useState<boolean>(false);
    const { drawnObjects } = useSelector((state: CombinedState) => state.annotation.canvas.instance?.view);
    const { activatedStateID } = useSelector((state: CombinedState) => state.annotation.annotations);

    useEffect(() => {
        if (canvasRef.current) {
            const sortedKeys = Object.keys(contextImageData).sort();
            const key = sortedKeys[contextImageOffset];
            const image = contextImageData[key];
            const context = canvasRef.current.getContext('2d');

            if (context && image) {
                const transformationMatrix = sensorCalibrations?.[key].transformation_matrix;
                const cameraIntrinsic = sensorCalibrations?.[key].camera_intrinsic;
                canvasRef.current.width = image.width;
                canvasRef.current.height = image.height;
                context.setTransform(zoomLevel, 0, 0, zoomLevel, dragOffset.x, dragOffset.y);
                context.drawImage(image, 0, 0);
                if (drawnObjects) {
                    Object.keys(drawnObjects).forEach((cuboidKey) => {
                        const cuboid = drawnObjects[cuboidKey];
                        if (cuboid && transformationMatrix &&
                            cameraIntrinsic &&
                            !cuboid.data.outside &&
                            !cuboid.data.hidden
                        ) {
                            // Assuming cuboid.data holds the necessary cuboid info
                            const corners = extractCubeCorners(cuboid.cuboid);
                            corners.forEach((point: number[]) => point.push(1));
                            const { clientID, labelColor } = cuboid.data;
                            const transformedCorners = transformCorners(
                                math.transpose(arrayToMatrix(corners)),
                                arrayToMatrix(transformationMatrix),
                            );
                            // filter out the points that are behind the camera
                            let cornersOutOfVew = 0;
                            for (const corner of transformedCorners) {
                                if (corner[2] < 0.1) {
                                    cornersOutOfVew += 1;
                                }
                            }
                            if (cornersOutOfVew === 0) {
                                const pixelPositions = getPixelPositions(transformedCorners, cameraIntrinsic);
                                drawCube(
                                    context,
                                    pixelPositions,
                                    labelColor,
                                    clientID,
                                    image,
                                    cuboid.data.clientID === activatedStateID,
                                );
                            }
                        }
                    });
                }
                context.translate(dragOffset.x, dragOffset.y);
            }
        }
    }, [
        contextImageData,
        contextImageOffset,
        canvasRef,
        JSON.stringify(drawnObjects),
        dragOffset,
        zoomLevel,
        activatedStateID,
    ]);

    const handleWheel = (event: WheelEvent): void => {
        event.preventDefault();
        const zoomSpeed = 0.1;
        setZoomLevel((prevZoomLevel) => {
            const newZoomLevel = prevZoomLevel + (event.deltaY > 0 ? -zoomSpeed : zoomSpeed);
            return Math.max(0.1, Math.min(newZoomLevel, 10));
        });
    };

    const handleMouseDown = (event: MouseEvent): void => {
        setIsDragging(true);
        setDragStart({ x: event.clientX, y: event.clientY });
    };

    const handleMouseMove = (event: MouseEvent): void => {
        if (isDragging) {
            // Calculate how much the mouse has moved since dragging started
            const deltaX = event.clientX - dragStart.x;
            const deltaY = event.clientY - dragStart.y;

            // Update the drag offset based on mouse movement
            setDragOffset((prevOffset) => ({
                x: prevOffset.x + deltaX,
                y: prevOffset.y + deltaY,
            }));

            // Update dragStart to current mouse position
            setDragStart({ x: event.clientX, y: event.clientY });
        }
    };

    const handleDoubleClick = (event: MouseEvent): void => {
        event.preventDefault();
        setIsDragging(false);
        setDragOffset({ x: 0, y: 0 });
        setDragStart({ x: 0, y: 0 });
        setZoomLevel(1);
    };

    const handleMouseUp = (): void => {
        setIsDragging(false);
    };

    useEffect(() => {
        const canvas = canvasRef.current;
        if (canvas) {
            canvas.addEventListener('wheel', handleWheel);
            canvas.addEventListener('mousedown', handleMouseDown);
            canvas.addEventListener('mousemove', handleMouseMove);
            canvas.addEventListener('mouseup', handleMouseUp);
            canvas.addEventListener('dblclick', handleDoubleClick);
            return (): void => {
                canvas.removeEventListener('wheel', handleWheel);
                canvas.removeEventListener('mousedown', handleMouseDown);
                canvas.removeEventListener('mousemove', handleMouseMove);
                canvas.removeEventListener('mouseup', handleMouseUp);
                canvas.removeEventListener('dblclick', handleDoubleClick);
            };
        }
        return () => null;
    }, [handleWheel, handleMouseDown, handleMouseMove, handleMouseUp]);

    useEffect(() => {
        let unmounted = false;
        const promise = job?.frames.contextImage(frameIndex);
        setFetching(true);
        promise
            .then((imageBitmaps: Record<string, ImageBitmap>) => {
                if (!unmounted) {
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    const { sensor_calibrations, ...rest } = imageBitmaps;
                    // @ts-ignore
                    setSensorCalibrations(sensor_calibrations);
                    setContextImageData(rest);
                }
            })
            .catch((error: any) => {
                if (!unmounted) {
                    setHasError(true);
                    notification.error({
                        message: `Could not fetch context images. Frame: ${frameIndex}`,
                        description: error.toString(),
                    });
                }
            })
            .finally(() => {
                if (!unmounted) {
                    setFetching(false);
                }
            });

        return () => {
            setContextImageData({});
            unmounted = true;
        };
    }, [frameIndex]);

    const contextImageName = Object.keys(contextImageData).sort()[contextImageOffset];
    return (
        <div className='calipergt-context-image-wrapper'>
            <div className='calipergt-context-image-header'>
                {relatedFiles > 1 && (
                    <SettingOutlined
                        className='calipergt-context-image-setup-button'
                        onClick={() => {
                            setShowSelector(true);
                        }}
                    />
                )}
                <div className='calipergt-context-image-title'>
                    <CaliperGTTooltip title={contextImageName}>
                        <Text>{contextImageName}</Text>
                    </CaliperGTTooltip>
                </div>
            </div>
            {(hasError || (!fetching && contextImageOffset >= Object.keys(contextImageData).length)) && (
                <Text> No data </Text>
            )}
            {fetching && <Spin size='small' />}
            {contextImageOffset < Object.keys(contextImageData).length && <canvas ref={canvasRef} />}
            {showSelector && (
                <ContextImageSelector
                    images={contextImageData}
                    offset={contextImageOffset}
                    onChangeOffset={(newContextImageOffset: number) => {
                        setContextImageOffset(newContextImageOffset);
                    }}
                    onClose={() => {
                        setShowSelector(false);
                    }}
                />
            )}
        </div>
    );
}

ContextImage.PropType = {
    offset: PropTypes.arrayOf(PropTypes.number),
};

export default React.memo(ContextImage);
