// Copyright (C) 2021-2022 Intel Corporation
// Copyright (C) 2022-2023 CaliperAI Corporation
//
// SPDX-License-Identifier: MIT

import React, { useCallback } from 'react';
import Text from 'antd/lib/typography/Text';
import Collapse from 'antd/lib/collapse';

import ObjectButtonsContainer from 'containers/annotation-page/standard-workspace/objects-side-bar/object-buttons';
import ItemDetailsContainer from 'containers/annotation-page/standard-workspace/objects-side-bar/object-item-details';
import { ObjectType, ShapeType, ColorBy } from 'reducers';
import { Col, Row } from 'antd/lib/grid';
import ItemBasics from './object-item-basics';

interface Props {
    normalizedKeyMap: Record<string, string>;
    readonly: boolean;
    activated: boolean;
    objectType: ObjectType;
    shapeType: ShapeType;
    clientID: number;
    serverID: number | null;
    labelID: number;
    isGroundTruth: boolean;
    points: number[] | null;
    rotation: number | null;
    locked: boolean;
    elements: number[];
    color: string;
    colorBy: ColorBy;
    labels: any[];
    attributes: any[];
    jobInstance: any;
    activate(activeElementID?: number): void;
    copy(): void;
    propagate(): void;
    createURL(): void;
    switchOrientation(): void;
    toBackground(): void;
    toForeground(): void;
    remove(): void;
    changeLabel(label: any): void;
    changeColor(color: string): void;
    resetCuboidPerspective(): void;
    edit(): void;
    slice(): void;
}

function getHeightWidth(points: number[]): JSX.Element {
    const height = Math.round(points[3] - points[1]);
    const width = Math.round(points[2] - points[0]);
    return (
        <>
            <Row gutter={[16, 16]}>
                <Col span={6}>Height:</Col>
                <Col span={6}>{`${height} px`}</Col>
            </Row>
            <Row gutter={[16, 16]}>
                <Col span={6}>Width:</Col>
                <Col span={6}>{`${width} px`}</Col>
            </Row>
        </>
    );
}

function getLengthWidthHeight(points: number[]): JSX.Element {
    const length = points[7].toFixed(2);
    const width = points[6].toFixed(2);
    const height = points[8].toFixed(2);
    return (
        <>
            <Row gutter={[16, 16]}>
                <Col span={6}>Length:</Col>
                <Col span={6}>{`${length} m`}</Col>
            </Row>
            <Row gutter={[16, 16]}>
                <Col span={6}>Width:</Col>
                <Col span={6}>{`${width} m`}</Col>
            </Row>
            <Row gutter={[16, 16]}>
                <Col span={6}>Height:</Col>
                <Col span={6}>{`${height} m`}</Col>
            </Row>
        </>
    );
}

function getRotation(points: number[]): JSX.Element {
    const rx = points[3].toFixed(2);
    const ry = points[4].toFixed(2);
    const rz = points[5].toFixed(2);
    return (
        <>
            <Row gutter={[16, 16]}>
                <Col span={6}>rx: </Col>
                <Col span={6}>{rx}</Col>
            </Row>
            <Row gutter={[16, 16]}>
                <Col span={6}>ry: </Col>
                <Col span={6}>{ry}</Col>
            </Row>
            <Row gutter={[16, 16]}>
                <Col span={6}>rz: </Col>
                <Col span={6}>{rz}</Col>
            </Row>
        </>
    );
}

function getPosition(points: number[]): JSX.Element {
    const x = points[0].toFixed(2);
    const y = points[1].toFixed(2);
    const z = points[2].toFixed(2);
    return (
        <>
            <Row gutter={[16, 16]}>
                <Col span={6}>x: </Col>
                <Col span={6}>{`${x} m`}</Col>
            </Row>
            <Row gutter={[16, 16]}>
                <Col span={6}>y: </Col>
                <Col span={6}>{`${y} m`}</Col>
            </Row>
            <Row gutter={[16, 16]}>
                <Col span={6}>z: </Col>
                <Col span={6}>{`${z} m`}</Col>
            </Row>
        </>
    );
}

function ObjectItemComponent(props: Props): JSX.Element {
    const {
        activated,
        readonly,
        objectType,
        shapeType,
        clientID,
        serverID,
        locked,
        labelID,
        color,
        colorBy,
        // elements,
        // attributes,
        labels,
        normalizedKeyMap,
        isGroundTruth,
        points,
        rotation,
        activate,
        copy,
        propagate,
        createURL,
        switchOrientation,
        toBackground,
        toForeground,
        remove,
        changeLabel,
        changeColor,
        resetCuboidPerspective,
        edit,
        slice,
        jobInstance,
    } = props;

    const type =
        objectType === ObjectType.TAG ?
            ObjectType.TAG.toUpperCase() :
            `${shapeType.toUpperCase()} ${objectType.toUpperCase()}`;

    const className = !activated ?
        'calipergt-objects-sidebar-state-item' :
        'calipergt-objects-sidebar-state-item calipergt-objects-sidebar-state-active-item';

    const activateState = useCallback(() => {
        activate();
    }, []);

    // Function for render details(include height width and rotation )
    const renderDetails = (): JSX.Element => (
        <Collapse.Panel
            header='Details'
            key='details'
        >
            <div className='calipergt-objects-sidebar-state-item-container'>
                {/* 2d Animation */}
                {points && (
                    <>
                        {shapeType === 'rectangle' && (
                            <div>
                                {getHeightWidth(points)}
                                {rotation !== null && (
                                    <Row gutter={[16, 16]}>
                                        <Col span={6}>Rotation:</Col>
                                        <Col span={6}>{rotation.toFixed(2)}</Col>
                                    </Row>
                                )}
                            </div>
                        )}

                        {/* Display for Cuboid 3D Annotation */}
                        {activated && shapeType === 'cuboid' && jobInstance.dimension === '3d' && (
                            <div>
                                <Text style={{ fontSize: 14, color: 'white', paddingLeft: '5px' }} type='secondary'>
                                    Size:
                                </Text>
                                <hr />
                                {getLengthWidthHeight(points)}
                                <hr />
                                <Text style={{ fontSize: 14, color: 'white', paddingLeft: '5px' }} type='secondary'>
                                    Rotation:
                                </Text>
                                <hr />
                                {getRotation(points)}
                                <hr />
                                <Text style={{ fontSize: 14, color: 'white', paddingLeft: '5px' }} type='secondary'>
                                    Position:
                                </Text>
                                <hr />
                                { getPosition(points) }
                            </div>
                        )}
                    </>
                )}
            </div>
        </Collapse.Panel>
    );

    const showDetails = shapeType === 'rectangle' || (shapeType === 'cuboid' && jobInstance.dimension === '3d');

    return (
        <div style={{ display: 'flex', marginBottom: '1px' }}>
            <div className='calipergt-objects-sidebar-state-item-color' />
            <div
                onMouseEnter={activateState}
                id={`calipergt-objects-sidebar-state-item-${clientID}`}
                className={className}
            >
                <ItemBasics
                    jobInstance={jobInstance}
                    readonly={readonly}
                    serverID={serverID}
                    clientID={clientID}
                    labelID={labelID}
                    labels={labels}
                    shapeType={shapeType}
                    objectType={objectType}
                    color={color}
                    colorBy={colorBy}
                    type={type}
                    locked={locked}
                    isGroundTruth={isGroundTruth}
                    copyShortcut={normalizedKeyMap.COPY_SHAPE}
                    pasteShortcut={normalizedKeyMap.PASTE_SHAPE}
                    propagateShortcut={normalizedKeyMap.PROPAGATE_OBJECT}
                    toBackgroundShortcut={normalizedKeyMap.TO_BACKGROUND}
                    toForegroundShortcut={normalizedKeyMap.TO_FOREGROUND}
                    removeShortcut={normalizedKeyMap.DELETE_OBJECT}
                    changeColorShortcut={normalizedKeyMap.CHANGE_OBJECT_COLOR}
                    sliceShortcut={normalizedKeyMap.SWITCH_SLICE_MODE}
                    changeLabel={changeLabel}
                    changeColor={changeColor}
                    copy={copy}
                    remove={remove}
                    propagate={propagate}
                    createURL={createURL}
                    switchOrientation={switchOrientation}
                    toBackground={toBackground}
                    toForeground={toForeground}
                    resetCuboidPerspective={resetCuboidPerspective}
                    edit={edit}
                    slice={slice}
                />
                <ObjectButtonsContainer readonly={readonly} clientID={clientID} />
                <Collapse
                    className='calipergt-objects-sidebar-state-item-collapse'
                    defaultActiveKey={['details', 'attributes']}
                >
                    {showDetails && renderDetails()}
                    <Collapse.Panel
                        header='Attributes'
                        key='attributes'
                    >
                        <ItemDetailsContainer readonly={readonly} clientID={clientID} parentID={null} />
                    </Collapse.Panel>
                </Collapse>
            </div>
        </div>
    );
}

export default React.memo(ObjectItemComponent);
